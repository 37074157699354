/*
 * @Description: 无
 * @version: 1.0.0
 * @Company: sdbean
 * @Author: hammercui
 * @Date: 2019-12-10 16:23:00
 * @LastEditors: hammercui
<<<<<<< HEAD
 * @LastEditTime: 2019-12-27 17:36:06
=======
<<<<<<< HEAD
<<<<<<< HEAD
 * @LastEditTime: 2019-12-25 16:06:40
=======
 * @LastEditTime: 2019-12-24 15:14:37
>>>>>>> parent of 405a518... 回滚到带router版本
=======
 * @LastEditTime: 2019-12-25 15:28:37
>>>>>>> 405a518f7fa9b091aab338dc2a08cd52c0897345
>>>>>>> e4df4edfd3f929282857ea0c0ca92a815a48f0f7
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
// const Home = ()=> import('../views/Home.vue')
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('../views/OfficialWeb/index.vue')
  },
  // {
  //   path: '/hsSword',
  //   name: 'hsSword',
  //   component: ()=> import('../views/HsSword/index.vue')
  // },
  // {
  //   path: '/Valentine',
  //   name: 'Valentine',
  //   component: () => import('../views/Valentine/index.vue')
  // },
  // {
  //   path: '/3d',
  //   name: '3d',
  //   component: ()=> import('../views/3dCarousels.vue')
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router