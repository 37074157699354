/*
 * @Description: store根节点，按模块拆分
 * @version: 1.0.0
 * @Company: sdbean
 * @Author: hammercui
 * @Date: 2019-12-10 16:22:43
 * @LastEditors: hammercui
 * @LastEditTime: 2019-12-17 13:43:40
 */
import Vue from 'vue'
import Vuex from 'vuex'
import hsSword from './hsSword'
import Valentine from './Valentine'
import wslLeague from './wslLeague'


import createLoadingPlugin from "../utils/vuex-loading";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createLoadingPlugin()],
  modules:{
    hsSword,
    Valentine,
    wslLeague
  }
})
