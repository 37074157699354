/* eslint-disable no-undef */
/* eslint-disable no-console */
/*
 * @Description: 无
 * @version: 1.0.0
 * @Company: sdbean
 * @Author: hammercui
 * @Date: 2019-12-11 10:53:25
 * @LastEditors: hammercui
 * @LastEditTime: 2019-12-26 13:15:43
 */
import axios from 'axios';
import qs from 'qs';
import { showToast } from './../utils/utils';

//全局缓存map
const GlobalCacheMap = {};

export function setGlobalMapValue(key, value) {
	if (value) {
		//cookie 空格替换为+
		if (key == 'cookie' && value.indexOf(" ") != -1) {
			console.info('cookie存在空格', key, value);
			value = value.replace(/\s/g, "+");
		}
		console.info('set', key, value);
		GlobalCacheMap[key] = value;
	}
}

export function getGlobalMapValue(key) {
	return GlobalCacheMap[key];
}

//缺省base url
export function baseUrl() {
	return `/Werewolf/werewolf/data/`;
}

export function request(url, option) {
	let realUrl = '';
	if (option.isHaveBase) {
		realUrl = url;
	} else {
		realUrl = baseUrl() + url;
	}

	const method = option.method;
	const body = option.body;
	if (getGlobalMapValue('userNo')) {
		body['userNo'] = getGlobalMapValue('userNo');
	}
	if (getGlobalMapValue('cookie')) {
		body['cookie'] = getGlobalMapValue('cookie');
	}

	// console.group(method, realUrl);
	// console.log('request body-->:', body);
	if (method === 'GET') {
		return axios
			.get(realUrl, { params: body })
			.then((response) => {
				// console.log('response body<--:', response.data);
				// console.groupEnd();
				return response.data;
			})
			.catch((err) => {
				// console.error('response err<--:', err);
				// console.groupEnd();
				if (err.response && err.response.data) {
					return err.response.data;
				}
				throw err;
			});
	} else {
		return axios
			.post(realUrl, qs.stringify(body))
			.then((response) => {
				// console.log('response body<--:', response.data);
				// console.groupEnd();
				if (response.data.code === 5002) {
					showToast('活动未开始');
					// throw new Error('活动未开始');
				}
				if (response.data.code === 5003) {
					//跳转到活动结束
					window.location.href =
						'https://werewolf.53site.com/Werewolf/DailyMission/2019/activityEnd/activityEnd.html'; //活动已结束
					// throw new Error('活动已结束');
				}
				return response.data;
			})
			.catch((err) => {
				// console.error('response err<--:', err);
				if (err.response) {
					// console.error('response body<--:', err.response.data);
				}
				// console.groupEnd();
				if (err.response && err.response.data) {
					return err.response.data;
				}
				throw err;
			});
	}
}
