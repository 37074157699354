/*
 * @Description: 活动大厅，公告，邮箱
 * @version: 1.0.0
 * @Company: sdbean
 * @Author: hammercui
 * @Date: 2019-12-11 11:13:47
 * @LastEditors: hammercui
 * @LastEditTime: 2019-12-23 16:01:47
 */
import {request} from '../utils/request';

let ATV_NAME_TEMP = 'HsSword'
if(process.env.VUE_APP_MODE == 'beta'){
    ATV_NAME_TEMP += 'Beta';
}
//导出活动名
export const ATV_NAME = ATV_NAME_TEMP;

//首页信息
export async function postInfo(params){
    return request(`${ATV_NAME_TEMP}/info.php`,{
        method: 'POST',
        body: params
    })
}

//设置上次选中称号id
export async function postSetTagId(params){
    return request(`${ATV_NAME_TEMP}/setTagId.php`,{
        method: 'POST',
        body: params
    })
}

//主播粉丝排行榜
export async function postAnchorFunsRank(params){
    return request(`${ATV_NAME_TEMP}/anchorFunsRank.php`,{
        method: 'POST',
        body: params
    })
}

//主播排行榜
export async function postAnchorRank(params){
    return request(`${ATV_NAME_TEMP}/anchorRank.php`,{
        method: 'POST',
        body: params
    })
}

//工会粉丝排行榜
export async function postGroupFunsRank(params){
    return request(`${ATV_NAME_TEMP}/groupFunsRank.php`,{
        method: 'POST',
        body: params
    })
}

//工会排行榜
export async function postGroupRank(params){
    return request(`${ATV_NAME_TEMP}/groupRank.php`,{
        method: 'POST',
        body: params
    })
}

//商店购买
export async function postShopBuy(params){
    return request(`${ATV_NAME_TEMP}/shopBuy.php`,{
        method: 'POST',
        body: params
    })
}

//商店商品列表
export async function postShopList(params){
    return request(`${ATV_NAME_TEMP}/shopList.php`,{
        method: 'POST',
        body: params
    })
}

//升级称号
export async function postUpTag(params){
    return request(`${ATV_NAME_TEMP}/upTag.php`,{
        method: 'POST',
        body: params
    })
}

//给主播投票
export async function postVoteAnchor(params){
    return request(`${ATV_NAME_TEMP}/voteAnchor.php`,{
        method: 'POST',
        body: params
    })
}

//给工会投票
export async function postVoteGroup(params){
    return request(`${ATV_NAME_TEMP}/voteGroup.php`,{
        method: 'POST',
        body: params
    })
}

//某tag详情
export async function postTagDetail(params){
    return request(`${ATV_NAME_TEMP}/tagDetail.php`,{
        method: 'POST',
        body: params
    })
}


