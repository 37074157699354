/* eslint-disable no-undef */
/* eslint-disable no-console */
/*
 * @Description: 华山论剑模块state
 * @version: 1.0.0
 * @Company: sdbean
 * @Author: hammercui
 * @Date: 2019-12-11 13:54:36
 * @LastEditors: hammercui
 * @LastEditTime: 2019-12-18 15:08:17
 */
/* eslint-disable no-unused-vars */

import * as _ from 'lodash';
import {
	postInfo,
	postExchangeSingle,
	postExchangeCp,
	postRefreshFriend,
	postRecWelAward,
	postAddFriend
} from '../apis/Valentine';
import {
	showToast,receiveShow
} from '../utils/utils';
import Vue from 'vue';
const notice = {
	namespaced: true,

	state: {
		pageState: 0, // 1:单人界面  2：cp界面
		dialogData: null,
		isHaveCp: 0, //是否拥有cp
		activityState: 1000, //0活动未开始1活动进行中2活动一结束
		singleInfo: { //单人信息
			leftCharm: 559, //剩余可兑亲密值
			isCanRefresh: 0, //好友列表是否能刷新
			awardList: [ //奖品列表状态
				{
					awardId: 1, //奖品id
					state: 2 //状态0不可领取，1可领取，2已领取
				},
				{
					awardId: 2,
					state: 0
				},
				{
					awardId: 3,
					state: 0
				},
				{
					awardId: 4,
					state: 0
				},
				{
					awardId: 5,
					state: 1
				}
			],
			userList: [ //可申请好友列表
				{
					userNo: 5046996,
					avatar: "http://img.53site.com/Werewolf/headicon/29b673befc47be1e42e986fa3e94a252.png",
					nickname: "test-57",
					nobleLevel: 0,
					nobelLight: 0,
					sex: 0,
					isApplied: 0,
					frameIcon: "https://datacenter.53site.com//Werewolf/frame/69_player.png",
					noteIcon: "https://werewolf.53site.com/Werewolf/frame_note/0.png",
					groupName: "快速通道"
				},
			]
		},
		cpInfo: { //cp信息
			leftEnergy: 0, //剩余可用能量
			isNewCp: 0, //是否能领取迎新奖励0否1能
			cpData: {
				intimacy: 22430, //cp亲密值
				level: 1, //cp等级
				createtime: "2019-06-17 15:17:26" //cp建立时间
			},
			userList: [ //两人信息
				{
					userNo: 4754489,
					avatar: "http://headicon.53site.com/headicon/20190920/u_4754489_352c0ef2add3bcd79ad1737b799dca3b.png",
					nickname: "0101010101",
					sex: 0,
					frameIcon: "https://datacenter.53site.com//Werewolf/frame/966_player.png",
					noteIcon: "https://werewolf.53site.com/Werewolf/frame_note/1.png"
				},
				{
					userNo: 4754495,
					avatar: "http://headicon.53site.com/headicon/20190920/u_4754495_8d757132468962d67eef21170a165d41.png",
					nickname: "0202202020",
					sex: 2,
					frameIcon: "https://datacenter.53site.com//Werewolf/frame/977_player.png",
					noteIcon: "https://werewolf.53site.com/Werewolf/frame_note/1.png"
				}
			],
			awardList: { //皮肤是否兑换过，key=awardId,value(0没有，1已拥有)
				1: 0,
				2: 0,
				3: 0,
				4: 0,
				5: 0,
				6: 0
			}
		}
	},

	//异步
	actions: {
		//请求首页信息
		async fetchInfo({
			dispatch,
			commit
		}, payload) {
			try {
				const response = await postInfo(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setInfo', response.data);
				} else {
					showToast(response.msg)
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求刷新好友添加列表
		async fetchRefreshFriend({
			dispatch,
			commit
		}, payload) {
			try {
				const response = await postRefreshFriend(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setFriendList', response.data);
				} else {
					showToast(response.msg)
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求单人兑换奖励
		async fetchExchangeSingle({
			dispatch,
			commit
		}, payload) {
			try {
				const response = await postExchangeSingle(payload);
				//success
				if (response.code == 1) {
					commit('updateSingleAward', payload.awardId);
					showToast('领取成功')
				} else {
					showToast(response.msg)
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求cp兑换奖励
		async fetchExchangeCp({
			dispatch,
			commit,
			state
		}, payload) {
			try {
				const response = await postExchangeCp(payload);
				//success
				if (response.code == 1) {
					commit('updateCpAward', payload.awardId);
					state.cpInfo.leftEnergy = response.data.leftEnergy;
					showToast('兑换成功')
				} else {
					showToast(response.msg)
					console.log('err msg', response.msg);
				}
				payload.callback();
			} catch (err) {
				console.error(err);
			}
		},
		//请求领取cp欢迎奖励
		async fetchRecCpWelcome({
			dispatch,
			commit
		}, payload) {
			try {
				const response = await postRecWelAward(payload);
				//success
				if (response.code == 1) {
					commit('updateCpWelocme');
					//恭喜活动弹窗
					receiveShow([{
						name: '520【礼物】',
						pic: 'http://img.53site.com/Werewolf/giftPack/gift_136.png',
						num: 1,
						title: '初遇之礼到手，感谢每一次相遇。',
						webMsg: '',
						bottomMsg: ''
					}])
				} else {
					showToast(response.msg)
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},

		//请求添加好友
		async fetchAddFriend({
			dispatch,
			commit
		}, payload) {
			try {
				const response = await postAddFriend(payload);
				//success
				if (response.sign == 1) {
					//添加好友成功
					payload.callback();
				}else{
					showToast(response.result);
				}
			} catch (err) {
				console.error(err);
			}
		}
	},

	//同步
	mutations: {
		changeUserlistSendState(state, payload) {
			state.singleInfo.userList[payload.id].isApplied = payload.isApplied;
		},
		//跳转到cp界面
		turnToCPPage(state, payload) {
			if (state.isHaveCp) {
				return;
			}
			state.pageState = 2;
		},
		turnToPersonalPage(state, payload) {
			if (state.isHaveCp) {
				return;
			}
			state.pageState = 1;
		},
		//控制二级弹窗
		awardDialogControl(state, payload) {
			state.dialogData = payload;
		},
		//更新首页信息
		setInfo(state, payload) {
			state.isHaveCp = payload.isHaveCp;
			state.activityState = payload.atyState;
			//单人信息
			if (state.isHaveCp == 0) {
				state.singleInfo = payload.singleInfo;
				state.pageState = 1;
			}
			//cp信息
			else {
				state.cpInfo = payload.cpInfo;
				state.pageState = 2;
			}
			console.log('new state', state);
		},
		//更新好友列表
		setFriendList(state, payload) {
			state.singleInfo.isCanRefresh = payload.isCanRefresh;
			state.singleInfo.userList = payload.userList;
		},

		//更新单人奖励状态为已领取
		updateSingleAward(state, awardId) {
			// state.singleInfo.awardList[awardId - 1] = 2;
			Vue.set(state.singleInfo.awardList, [awardId - 1], {
				awardId: awardId - 1,
				state: 2
			});
			console.log(state.singleInfo.awardList);
		},
		//更新cp皮肤奖励状态为已领取
		updateCpAward(state, awardId) {
			if (awardId < 7) {
				// Vue.set(state.singleInfo.awardList, [awardId ], 1)
				state.cpInfo.awardList[awardId] = 1;
			}
		},
		//更新cp欢迎奖励
		updateCpWelocme(state) {
			state.cpInfo.isNewCp = 0;
		},
	}
};

export default notice;