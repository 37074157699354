import {request} from '../utils/request';

let ATV_NAME_TEMP = 'WSL'
if(process.env.VUE_APP_MODE == 'beta'){
    ATV_NAME_TEMP += 'Beta';
}
//导出活动名
export const ATV_NAME = ATV_NAME_TEMP;

//首页信息
export async function postInfo(params){
    return request(`${ATV_NAME_TEMP}/info.php`,{
        method: 'POST',
        body: params
    })
}