/*
 * @Description: 活动大厅，公告，邮箱
 * @version: 1.0.0
 * @Company: sdbean
 * @Author: hammercui
 * @Date: 2019-12-11 11:13:47
 * @LastEditors: hammercui
 * @LastEditTime: 2019-12-17 11:42:13
 */

import {request} from '../utils/request';

let ATV_NAME_TEMP = 'Vday'
if(process.env.VUE_APP_MODE == 'beta'){
    ATV_NAME_TEMP += 'Beta';
}
//导出活动名
export const ATV_NAME = ATV_NAME_TEMP;


//首页信息
export async function postInfo(params){
    return request(`${ATV_NAME_TEMP}/info.php`,{
        method: 'POST',
        body: params
    })
}

//刷新好友列表
export async function postRefreshFriend(params){
    return request(`${ATV_NAME_TEMP}/refreshFriend.php`,{
        method: 'POST',
        body: params
    })
}

//兑换单人奖励
export async function postExchangeSingle(params){
    return request(`${ATV_NAME_TEMP}/exchangeSingle.php`,{
        method: 'POST',
        body: params
    })
}
//兑换个人奖励
export async function postExchangeCp(params){
    return request(`${ATV_NAME_TEMP}/exchangeCp.php`,{
        method: 'POST',
        body: params
    })
}
//领取欢迎奖励
export async function postRecWelAward(params){
    return request(`${ATV_NAME_TEMP}/recWelAward.php`,{
        method: 'POST',
        body: params
    })
}

//请求发送好友申请
export async function postAddFriend(params){
    // console.log("process.env.VUE_APP_MAIN_PHP_API_URI",process.env.VUE_APP_MAIN_PHP_API_URI);
    let mainPhpUrl = process.env.VUE_APP_MAIN_PHP_API_URI;
    return request(`${mainPhpUrl}sendFriendMessage.php`,{
        isHaveBase:true,//是否已存在基础地址
        method: 'POST',
        body: params
    })
}