/* eslint-disable no-undef */
/* eslint-disable no-console */
/*
 * @Description: 华山论剑模块state
 * @version: 1.0.0
 * @Company: sdbean
 * @Author: hammercui
 * @Date: 2019-12-11 13:54:36
 * @LastEditors: hammercui
 * @LastEditTime: 2019-12-24 11:46:40
 */
/* eslint-disable no-unused-vars */
import {
	postInfo,
	postSetTagId,
	postUpTag,
	postAnchorRank,
	postAnchorFunsRank,
	postGroupRank,
	postGroupFunsRank,
	postShopList,
	postShopBuy,
	postTagDetail,
	postVoteAnchor,
	postVoteGroup
} from '../apis/hsSword';
import * as _ from 'lodash';
import Vue from 'vue';
import { showToast, receiveShow } from './../utils/utils';
import { setGlobalMapValue } from '../utils/request';
const notice = {
	namespaced: true,

	state: {
		swordTie: 0, //拜帖
		swordPu: 0, //剑谱
		swordQi: 0, //剑气
		diamond: 0, //钻石
		isCanVote: 0, //是否能投票
		isCanUpTag: 0, //是否能升级tag
		clickItem: null, //当前点击的item
		isGameEnd: false, //赛季是否结束
		isShowRank:false,//是否显示排行榜
		atyTagList: [], //活动称号列表
		videoUrl: 'https://www.douyu.com/5609440/', //直播地址
		step: 0, //活动阶段0未开始1商城2称号升级3人气评选
		lastAtyTagId: 0, //上次选中的活动称号id
		anchorFunsRankList: [],
		anchorRankList: [],
		groupFunsRankList: [],
		groupRankList: [],
		shopTagList: [], //渠道1剑谱购买，渠道2钻石购买，渠道3都可以
		shopSkinList: [],
		shopPropsList: [],
		appShopPropsList: [
			[
				{
					name: '延时卡',
					pic: 'http://img.53site.com/Werewolf/pack/shop_prop_card_speak_overtime_new.png',
					num: 5,
					title: '哇！我拿到了华山论剑的限定奖励，羡慕么？',
					webMsg: '',
					bottomMsg: ''
				},
				{
					name: '示票卡',
					pic: 'http://img.53site.com/Werewolf/pack/shop_prop_card_show_voted_new.png',
					num: 5,
					title: '哇！我拿到了华山论剑的限定奖励，羡慕么？',
					webMsg: '',
					bottomMsg: ''
				},
				{
					name: '抢猎人卡',
					pic: 'http://img.53site.com/Werewolf/pack/role_4_card.png',
					num: 1,
					title: '哇！我拿到了华山论剑的限定奖励，羡慕么？',
					webMsg: '',
					bottomMsg: ''
				}
			],
			[
				{
					name: '延时沙漏',
					pic: 'http://img.53site.com/Werewolf/pack/shop_prop_card_hourglass.png',
					num: 1,
					title: '哇！我拿到了华山论剑的限定奖励，羡慕么？',
					webMsg: '',
					bottomMsg: ''
				},
				{
					name: '宝箱钥匙',
					pic: 'http://img.53site.com/Werewolf/pack/box_key.png',
					num: 1,
					title: '哇！我拿到了华山论剑的限定奖励，羡慕么？',
					webMsg: '',
					bottomMsg: ''
				},
				{
					name: '抢预言家卡',
					pic: 'http://img.53site.com/Werewolf/pack/role_2_card.png',
					num: 1,
					title: '哇！我拿到了华山论剑的限定奖励，羡慕么？',
					webMsg: '',
					bottomMsg: ''
				}
			]
		],
		shopGiftList: [],
		myVotedAnchorNum: 0, //我掉当前主播投票数
		myVoteGroupNum: 0, //我对当前工会投票数
		curMatchDay: 1, //当前日程,
		tagDetail: null,
		voteSucPuNum: 0, //投票成功后获得的剑谱数量
		fansRankShow:false,
	},

	//异步
	actions: {
		//请求首页信息
		async fetchInfo({ dispatch, commit }, payload) {
			setGlobalMapValue('activityStart', false);
			try {
				const response = await postInfo(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					setGlobalMapValue('activityStart', true);
					commit('setInfo', response.data);
				} else {
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},

		//请求更新lastTagId
		async fetchSetTagId({ dispatch, commit }, payload) {
			try {
				const response = await postSetTagId(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setLastTagId', response.data);
				} else {
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求主播粉丝投票排行榜
		async fetchAnchorFunsRank({ dispatch, commit }, payload) {
			try {
				const response = await postAnchorFunsRank(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setAnchorFunsRank', response.data);
					commit('setFansRankShow', true);
				} else {
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求主播排行榜
		async fetchAnchorRank({ dispatch, commit }, payload) {
			try {
				const response = await postAnchorRank(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setAnchorRank', response.data);
				} else {
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求工会粉丝投票排行榜
		async fetchGroupFunsRank({ dispatch, commit }, payload) {
			try {
				const response = await postGroupFunsRank(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setGroupFunsRank', response.data);
					commit('setFansRankShow', true);
				} else {
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求工会排行榜
		async fetchGroupRank({ dispatch, commit }, payload) {
			try {
				const response = await postGroupRank(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setGroupRank', response.data);
				} else {
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求购买或兑换商品
		async fetchShopBuy({ dispatch, commit, state }, payload) {
			try {
				const response = await postShopBuy(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setShopBuy', response.data);
					let appGiftData = state[response.data.keyName][response.data.index];
					console.log('appGiftData:', appGiftData);
					let appGiftDataItem = [];
					if (appGiftData.id == 35) {
						appGiftDataItem = state.appShopPropsList[0];
					} else if (appGiftData.id == 36) {
						appGiftDataItem = state.appShopPropsList[1];
					} else {
						appGiftDataItem = [
							{
								name: appGiftData.name,
								pic: appGiftData.icon,
								num: response.data.num,
								title: '哇！我拿到了华山论剑的限定奖励，羡慕么？',
								webMsg: '',
								bottomMsg: ''
							}
						];
					}
					console.log('appGiftDataItem=>', appGiftDataItem);
					receiveShow(appGiftDataItem);
				} else {
					console.log('err msg', response.msg);
					if (response.code != 2) {
						showToast(response.msg);
					}
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求商品列表
		async fetchShopList({ dispatch, commit }, payload) {
			try {
				const response = await postShopList(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setShopList', response.data);
				} else {
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求升级称号
		async fetchUpTag({ dispatch, commit }, payload) {
			try {
				const response = await postUpTag(payload);
				//success
				if (response.code == 1) {
					console.log('ready commit', response.data);
					commit('setUpTag', response.data);
					// //再次请求某tag详情
					// dispatch('fetchTagDetail', {atyTagId:payload.atyTagId});
					dispatch('fetchInfo', {});
					showToast('升级成功');
				} else {
					showToast(response.msg);
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求给主播投票
		async fetchVoteAnchor({ dispatch, commit }, payload) {
			try {
				const response = await postVoteAnchor(payload);
				//success
				if (response.code == 1) {
					console.log('投票主播获得剑谱数量', response.data.addSwordPu);
					commit('setVoteAnchor', response.data);
					//再次请求主播列表
					dispatch('fetchAnchorRank', {});
				} else {
					showToast(response.msg);
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求给工会投票
		async fetchVoteGroup({ dispatch, commit }, payload) {
			try {
				const response = await postVoteGroup(payload);
				//success
				if (response.code == 1) {
					console.log('投票主播获得剑谱数量', response.data.addSwordPu);
					commit('setVoteGroup', response.data);
					//再次请求工会列表
					dispatch('fetchGroupRank', {});
				} else {
					showToast(response.msg);
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		},
		//请求某tag详情
		async fetchTagDetail({ dispatch, commit }, payload) {
			try {
				const response = await postTagDetail(payload);
				//success
				if (response.code == 1) {
					console.log('获得某tag的详情列表', response.data);
					commit('setTagDetail', response.data);
				} else {
					console.log('err msg', response.msg);
				}
			} catch (err) {
				console.error(err);
			}
		}
	},

	//同步
	mutations: {
		setInfo(state, payload) {
			state.swordQi = payload.swordQi;
			state.swordPu = payload.swordPu;
			state.diamond = payload.diamond;
			state.atyTagList = payload.atyTagList;
			state.isCanVote = payload.isCanVote;
			state.isShowRank = payload.isShowRank;
			state.isCanUpTag = payload.isCanUpTag;
			state.lastAtyTagId = payload.lastAtyTagId;
			state.videoUrl = payload.videoUrl;
			state.curMatchDay = payload.curMatchDay;
			state.isGameEnd = payload.isGameEnd;
			console.log('new state', state);
		},

		setLastTagId(state, payload) {
			state.lastAtyTagId = payload.lastAtyTagId;
			console.log('new state', state);
		},
		setAnchorFunsRank(state, payload) {
			state.myVotedAnchorNum = payload.myVotedAnchorNum;
			state.anchorFunsRankList = payload.anchorFunsRankList;
			console.log('new state', state);
		},
		setAnchorRank(state, payload) {
			state.diamond = payload.diamond;
			state.swordTie = payload.swordTie;
			state.anchorRankList = payload.anchorRankList;
			console.log('new state', state);
		},
		setGroupFunsRank(state, payload) {
			state.myVoteGroupNum = payload.myVoteGroupNum;
			state.groupFunsRankList = payload.groupFunsRankList;
			console.log('new state', state);
		},
		setGroupRank(state, payload) {
			state.diamond = payload.diamond;
			state.swordTie = payload.swordTie;
			state.groupRankList = payload.groupRankList;
			console.log('new state', state);
		},

		setShopBuy(state, payload) {
			state.diamond = payload.diamond;
			state.swordPu = payload.swordPu;
			//刷新数组下标
			let oldItem = state[payload.keyName][payload.index];
			let newItem = _.cloneDeep(oldItem);
			newItem['owned'] = Number(oldItem['owned']) + Number(payload.num);
			newItem['isLimit'] = payload.isLimit;
			Vue.set(state[payload.keyName], payload.index, newItem);

			//刷新已购买称号
			if (payload.keyName == 'shopTagList') {
				let oldAtyTagList = state.atyTagList;
				let newAtyTagList = _.cloneDeep(oldAtyTagList);
				let newAtyTag = { name: newItem['name'], atyTagId: newItem['id'], level: 1, icon: newItem['icon'] };
				newAtyTagList.push(newAtyTag);
				state.atyTagList = newAtyTagList;
			}

			console.log('new state', state);
		},

		setShopList(state, payload) {
			state.diamond = payload.diamond;
			state.swordPu = payload.swordPu;
			state.shopTagList = payload.shopTagList;
			state.shopSkinList = payload.shopSkinList;
			state.shopPropsList = payload.shopPropsList;
			state.shopGiftList = payload.shopGiftList;
			console.log('new state', state);
		},
		setUpTag(state, payload) {
			let oldItem = state.atyTagList[payload.index];
			let newItem = _.cloneDeep(oldItem);
			newItem['level'] = payload.level;
			newItem['icon'] = payload.icon;
			Vue.set(state.atyTagList, state.index, newItem);
			console.log('new state', state);
		},
		setVoteAnchor(state, payload) {
			state.voteSucPuNum = payload.addSwordPu;
			state.swordPu += payload.addSwordPu;
			console.log('new state', state);
		},
		setVoteGroup(state, payload) {
			state.voteSucPuNum = payload.addSwordPu;
			state.swordPu += payload.addSwordPu;
			console.log('new state', state);
		},
		setClickItem(state, payload) {
			state.clickItem = payload;
		},
		setTagDetail(state, payload) {
			state.tagDetail = payload;
			console.log('new state', state);
		},
		setVoteSucPuNum(state, payload) {
			state.voteSucPuNum = payload;
		},
		setFansRankShow(state, payload) {
			state.fansRankShow = payload;
		},
	}
};

export default notice;
